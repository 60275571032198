<template>
  <div>
    <div class="row mt-5">
      <div class="text-start col-6">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_saldo_economato"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          filtrata
        </button>
      </div>
      <ModalEsportaSaldoEconomato />
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />

    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="data.length == 0 && loaded && (status == 200 || status == 204)"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-end card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="data"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRows"
        @current-change="setCurrentPage"
      >
        <template v-slot:cell-cod_affiliazione="{ row: data }"
          ><div>
            {{ data.cod_affiliazione }}
          </div>
        </template>
        <template v-slot:cell-societa="{ row: data }"
          ><div class="tab-long">
            {{ data.societa }}
          </div>
        </template>
        <template v-slot:cell-saldo="{ row: data }"
          ><div class="text-end">
            {{ data.saldo.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell-impegnato="{ row: data }"
          ><div class="text-end">
            {{ data.impegnato.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell-saldoPiuImpegnato="{ row: data }"
          ><div class="text-end">
            {{ data.saldoPiuImpegnato.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell-pagamenti="{ row: data }"
          ><div class="text-end">
            {{ data.pagamenti.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell-acquisti="{ row: data }"
          ><div class="text-end">
            {{ data.acquisti.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell-saldoSanzioni="{ row: data }"
          ><div class="text-end">
            {{ data.saldoSanzioni.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell-options="{ row: data }"
          ><div class="">
            <router-link
              :to="`/societa/dettaglio-societa/economato-societa/${data.id_societa}`"
              class="btn btn-sm dropdown"
            >
              <i class="bi bi-eye text-dark fs-4"></i> società
            </router-link>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import isEnabled from "@/composables/isEnabled.js";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ModalEsportaSaldoEconomato from "./ModalEsportaSaldoEconomato.vue";

export default {
  name: "TableSaldoEconomato",
  components: { Datatable, ModalEsportaSaldoEconomato },
  emits: ["getEconomatoList", "resetFilters"],
  props: {},

  setup(props, { emit }) {
    const tableHeader = ref([
      {
        name: "Codice affilizione",
        key: "cod_affiliazione",
      },
      {
        name: "Società",
        key: "societa",
      },
      {
        name: "Saldo",
        key: "saldo",
        align: "end",
      },
      {
        name: "Impegnato",
        key: "impegnato",
        align: "end",
      },
      {
        name: "Saldo + Impegnato",
        key: "saldoPiuImpegnato",
        align: "end",
      },
      {
        name: "Pagamenti",
        key: "pagamenti",
        align: "end",
      },
      {
        name: "Acquisti",
        key: "acquisti",
        align: "end",
      },
      {
        name: "Saldo sanzioni",
        key: "saldoSanzioni",
        align: "end",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const store = useStore();

    const setFetchRows = (e) => {
      store.commit("setFetchRowsSaldo", e);
      emit("getEconomatoList");
    };
    const setCurrentPage = (page) => {
      store.commit("setCurrentPageSaldo", page);
      emit("getEconomatoList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnSaldo", columnName);
      store.commit("setSortOrderSaldo", order);
      emit("getEconomatoList");
    };

    const esportaLista = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    return {
      tableHeader,
      setFetchRows,
      setCurrentPage,
      isEnabled,
      setSortOrderColumn,
      data: computed(() =>
        store.getters.getStateFromName("resultseconomato_saldo_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedeconomato_saldo_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordeconomato_saldo_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuseconomato_saldo_list")
      ),
      currentPage: computed(() => store.getters.currentPageSaldo),
      rowsToSkip: computed(() => store.getters.rowsToSkipSaldo),
      fetchRows: computed(() => store.getters.fetchRowsSaldo),
      sortColumn: computed(() => store.getters.sortColumnSaldo),
      sortOrder: computed(() => store.getters.sortOrderSaldo),
      esportaLista,
    };
  },
};
</script>

<style scoped></style>
