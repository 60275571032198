<template>
  <div>
    <FilterSaldoEconomato
      @getEconomatoList="getEconomatoListResetPage"
      @resetFilters="resetFilters"
    />
    <TableSaldoEconomato
      @getEconomatoList="getEconomatoList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import FilterSaldoEconomato from "@/components/components-fit/gestione/economato/FiltriSaldoEconomato.vue";
import TableSaldoEconomato from "@/components/components-fit/gestione/economato/TableSaldoEconomato.vue";

export default defineComponent({
  name: "saldo-economato",
  components: {
    FilterSaldoEconomato,
    TableSaldoEconomato,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Economato");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);

    const idComitatoRegionale = computed(
      () => store.getters.idComitatoRegionaleSaldo
    );
    const idSocieta = computed(() => store.getters.idSocietaSaldo);

    const startDateApprovazione = computed(
      () => store.getters.dataApprovazioneDaSaldo
    );
    const endDateApprovazione = computed(
      () => store.getters.dataApprovazioneASaldo
    );

    const rowsToSkip = computed(() => store.getters.rowsToSkipSaldo);
    const fetchRows = computed(() => store.getters.fetchRowsSaldo);
    const sortColumn = computed(() => store.getters.sortColumnSaldo);
    const sortOrder = computed(() => store.getters.sortOrderSaldo);

    const getEconomatoList = () => {
      const localKeys = {
        stagione: anno.value,
        id_Comitato: idComitatoRegionale.value,
        id_Societa: idSocieta.value,
        data_Approvazione_Da: startDateApprovazione.value,
        data_Approvazione_A: endDateApprovazione.value,
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.ECONOMATO_SALDI_LIST,
        itemName: "economato_saldo_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersSaldo");
      getEconomatoList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedeconomato_saldo_list")
    );
    const getEconomatoListResetPage = () => {
      if (!loaded.value) return;
      store.commit("resetRowsToSkipSaldo");
      getEconomatoList();
    };
    getEconomatoList();

    return {
      getEconomatoList,
      resetFilters,
      getEconomatoListResetPage,
    };
  },
});
</script>
